import React from "react";

function SaveMoney() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68"
            height="77"
            fill="none"
            viewBox="0 0 68 77"
        >
            <path
                fill="#F99D1C"
                d="M34.669 41.847a1.196 1.196 0 00-.431-.014 2.859 2.859 0 01-2.53-2.836 1.258 1.258 0 00-2.515 0 5.38 5.38 0 003.957 5.181v1.258a1.258 1.258 0 002.515 0v-1.182a5.371 5.371 0 00-1.1-10.628 2.857 2.857 0 01-2.02-4.875 2.857 2.857 0 014.875 2.02 1.258 1.258 0 102.516 0 5.377 5.377 0 00-4.27-5.258v-1.145a1.258 1.258 0 00-2.516 0v1.222a5.37 5.37 0 001.415 10.552 2.852 2.852 0 012.505 4.22 2.853 2.853 0 01-2.401 1.485z"
            ></path>
            <path
                fill="#F99D1C"
                d="M16.326 34.98a18.237 18.237 0 0018.239 18.24 18.237 18.237 0 0018.238-18.24 18.236 18.236 0 00-18.238-18.238 18.259 18.259 0 00-18.24 18.239zm18.238-15.722a15.725 15.725 0 0111.118 26.841 15.724 15.724 0 01-26.841-11.118 15.738 15.738 0 0115.723-15.723z"
            ></path>
            <path
                fill="#F99D1C"
                d="M59.003 56.32l-9.852 4.656a8.03 8.03 0 00-6.842-4.221l-9.095-.25a9.615 9.615 0 01-4.134-1.067l-.925-.48a16.324 16.324 0 00-15.122.016l.058-2.097a1.258 1.258 0 00-1.223-1.292l-9.974-.274a1.256 1.256 0 00-1.292 1.222L.001 74.421a1.253 1.253 0 001.222 1.291l9.974.275h.035c.68 0 1.238-.542 1.257-1.223l.028-1.047 2.591-1.388a4.45 4.45 0 013.318-.363l15.473 4.343.081.02a16.784 16.784 0 0010.41-1.18c.055-.024.107-.052.157-.085l22.434-14.51c.56-.362.74-1.096.413-1.676a6.35 6.35 0 00-8.39-2.556v-.001zM2.55 73.233l.532-19.372 7.46.205-.533 19.372-7.46-.205zm40.708-.329a14.26 14.26 0 01-8.722.973l-15.43-4.331a6.954 6.954 0 00-5.185.567l-1.324.71.354-12.881a13.821 13.821 0 0114.046-.75l.925.48a12.138 12.138 0 005.222 1.349l9.096.25a5.515 5.515 0 015.243 4.395l-13.549-.373a1.258 1.258 0 00-.069 2.514l14.952.412h.036c.68 0 1.237-.542 1.256-1.223a8.035 8.035 0 00-.122-1.632l10.106-4.775.029-.014a3.843 3.843 0 014.346.608l-21.21 13.721zM35.822 13.206V1.258a1.258 1.258 0 00-2.516 0v11.948a1.258 1.258 0 002.516 0zM45.254 13.207v-5.66a1.258 1.258 0 00-2.515 0v5.66a1.258 1.258 0 002.515 0zM26.388 13.207v-5.66a1.258 1.258 0 00-2.516 0v5.66a1.258 1.258 0 002.516 0z"
            ></path>
        </svg>
    );
}

export default SaveMoney;
