import React, { ReactElement } from "react";

import SaveMoney from "./icons/saveMoney";
import SaveTime from "./icons/saveTime";
import BonusBenefits from "./icons/bonusBenefits";

const benefitsList = [
    {
        title: "Save Money",
        description:
            "A home warranty saves homeowners money by covering repair or replacement costs, offering maintenance service discounts, and providing coverage for pre-existing conditions.",
        icon: <SaveMoney />,
    },
    {
        title: "Save Time",
        description:
            "Homeowners can file a claim to have a technician dispatched automatically within a day or two, without the hassle of finding a technician or scheduling appointments.",
        icon: <SaveTime />,
    },
    {
        title: "Bonus Benefits ",
        description:
            "Some home warranty companies provide additional benefits, such as discounts on home security, restaurants, or popular products.",
        icon: <BonusBenefits />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <section className=" lg:mt-32 mt-20 lg:mb-48 mb-28">
            <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#1D2458] text-center">
                The Benefits of a Home Warranty
            </h2>
            <div className="flex flex-col lg:flex-row justify-center gap-7 mt-12">
                {benefitsList.map((el, index) => (
                    <div
                        key={index}
                        className="sm:w-[371px] w-[300px] lg:mx-0  mx-auto  rounded-[14px] bg-[#1D2458] text-[#fff] lg:h-[530px] h-[460px] flex flex-col items-center px-6 relative"
                    >
                        <span className="mt-16 block">{el.icon}</span>
                        <h3 className="mt-9 font-bold lg:text-2xl text-xl">
                            {el.title}
                        </h3>
                        <p className="text-center mt-4 min-h-[196px] font-light lg:text-lg text-base lg:leading-8">
                            {el.description}
                        </p>
                        <span className="w-[86px] h-[9px] bg-[#F99D1C] block absolute bottom-7"></span>
                    </div>
                ))}
            </div>
        </section>
    );
}
