import React from "react";

function SaveTime() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="85"
            height="77"
            fill="none"
            viewBox="0 0 85 77"
        >
            <path
                fill="#F99D1C"
                d="M82 49.055a5.375 5.375 0 00-5.203-.302l-13.328 6.16c-.013-3.015-2.477-5.452-5.49-5.452h-9.162c-4.626-4.037-10.392-6.5-15.516-6.632a306.479 306.479 0 00-11.992-.026V40.42c0-2.045-1.664-3.71-3.695-3.71H3.709A3.714 3.714 0 000 40.42v27.664a3.714 3.714 0 003.709 3.709H17.6a3.702 3.702 0 003.695-3.71v-1.454c4.967 2.857 18.112 10.052 24.336 10.052.131 0 .276 0 .406-.014 10.917-.616 34.152-17.181 35.79-18.36a5.377 5.377 0 002.687-4.665A5.326 5.326 0 0082 49.054zM18.753 68.07c0 .629-.512 1.153-1.14 1.153H3.708c-.63 0-1.153-.51-1.153-1.153V40.404c0-.629.51-1.153 1.153-1.153H17.6c.63 0 1.14.511 1.14 1.153V68.07h.014zM80.531 56.09c-.039.026-.091.053-.13.079-.237.17-24.154 17.337-34.494 17.927-5.215.276-18.963-7.116-24.598-10.431V45.358c2.137-.039 6.776-.091 11.926.026 4.665.118 9.986 2.477 14.232 6.304.236.21.537.327.852.327h9.632c1.612 0 2.936 1.31 2.936 2.923 0 .262-.04.524-.105.773l-.04.118a3.217 3.217 0 01-.314.682c-.013.026-.026.052-.039.066a2.926 2.926 0 01-2.424 1.284H39.343c-.707 0-1.284.576-1.284 1.284 0 .708.577 1.284 1.284 1.284h18.623c1.782 0 3.342-.865 4.35-2.175l15.57-7.182a2.798 2.798 0 012.739.158 2.83 2.83 0 011.337 2.411 2.772 2.772 0 01-1.429 2.45z"
            ></path>
            <path
                fill="#F99D1C"
                d="M12.608 58.608a3.845 3.845 0 00-3.84 3.84c0 2.122 1.73 3.839 3.84 3.839s3.84-1.73 3.84-3.84c.013-2.11-1.717-3.84-3.84-3.84zm0 5.124a1.287 1.287 0 01-1.285-1.285c0-.707.577-1.284 1.285-1.284.707 0 1.284.577 1.284 1.284 0 .708-.577 1.285-1.284 1.285zM55.58 46.262c12.752 0 23.131-10.379 23.131-23.13C78.711 10.38 68.331 0 55.58 0 42.828 0 32.449 10.38 32.449 23.131c0 12.752 10.38 23.131 23.131 23.131zm0-43.692c11.336 0 20.563 9.226 20.563 20.563 0 11.336-9.227 20.562-20.563 20.562-11.336 0-20.563-9.227-20.563-20.562 0-11.336 9.227-20.561 20.563-20.561V2.57z"
            ></path>
            <path
                fill="#F99D1C"
                d="M55.58 9.267c.708 0 1.285-.577 1.285-1.285l-.001-1.795c0-.708-.577-1.284-1.285-1.284-.707 0-1.284.576-1.284 1.284v1.809c.014.707.577 1.27 1.285 1.27zM55.58 36.984c-.708 0-1.284.577-1.284 1.285v1.808c0 .708.576 1.285 1.284 1.285.708 0 1.285-.577 1.285-1.285V38.27c-.001-.709-.577-1.285-1.285-1.285zM43.968 13.33c.25.248.577.38.905.38a1.282 1.282 0 00.904-2.189l-1.271-1.272a1.282 1.282 0 00-1.809 0 1.282 1.282 0 000 1.81l1.271 1.27zM66.667 36.028c.249.25.577.38.904.38a1.282 1.282 0 00.904-2.189l-1.27-1.271a1.282 1.282 0 00-1.81 0 1.282 1.282 0 000 1.809l1.272 1.271zM41.727 23.131c0-.708-.577-1.284-1.284-1.284h-1.81c-.707 0-1.284.576-1.284 1.284 0 .708.577 1.285 1.285 1.285h1.809c.708 0 1.284-.577 1.284-1.285zM70.729 24.416h1.809c.707 0 1.284-.577 1.284-1.284 0-.708-.577-1.285-1.284-1.285h-1.81c-.707 0-1.284.577-1.284 1.285.001.707.578 1.284 1.285 1.284zM43.98 32.934l-1.284 1.285a1.282 1.282 0 00.904 2.189c.328 0 .656-.131.905-.38l1.271-1.285a1.282 1.282 0 000-1.809 1.265 1.265 0 00-1.796 0zM66.3 13.695c.327 0 .654-.13.904-.38l1.284-1.27a1.282 1.282 0 000-1.81 1.282 1.282 0 00-1.809 0l-1.284 1.285a1.282 1.282 0 000 1.809c.249.249.577.366.904.366zM55.58 27.3c.59 0 1.14-.144 1.651-.38l2.11 2.11c.25.249.577.38.905.38a1.282 1.282 0 00.904-2.189l-2.11-2.123c.235-.497.38-1.061.38-1.651a3.833 3.833 0 00-2.556-3.604v-7.417c0-.708-.577-1.285-1.285-1.285-.707 0-1.284.577-1.284 1.285v7.417a3.817 3.817 0 00-2.556 3.604A3.85 3.85 0 0055.58 27.3zm0-5.124c.708 0 1.285.576 1.285 1.284 0 .708-.577 1.285-1.285 1.285a1.287 1.287 0 01-1.285-1.285c0-.72.577-1.284 1.285-1.284z"
            ></path>
        </svg>
    );
}

export default SaveTime;
